/* You can add global styles to this file, and also import other style files */
/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;
    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .jhi-toast {
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }
  }
}

.table-entities thead th .d-flex > * {
  margin: auto 0;
}

.ngx-toastr {
  @media (min-width: 768px) {
    width: 550px !important;  //  Specify toastr-dialog width only on small devices 
  }
}

